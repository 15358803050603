
 .navbar-nav .dropdown-menu-right {
  right: auto !important;
}
 .navbar-nav .dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}
 .navbar-nav .dropdown-menu {
  position: absolute !important;
}
.dropdown-menu {
  padding-bottom: 0 !important;
  line-height: 1.5 !important;
}
.img-avatar, .avatar > img, .img-circle {
  max-width: 100%;
  height: 35px !important;
  margin: 0 10px !important;
  border-radius: 50em;
}

 .react-grid-item ul{
   width:100% !important;
   padding-top: 3px !important;
   margin-left: 0px !important;

 }
  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
    overflow:auto !important;
    overflow-x:hidden !important;
    scrollbar-width: thin;                                     
    scrollbar-color: rgb(218, 222, 223);
  }
  
  .react-grid-item::-webkit-scrollbar{
      width: 8px;
      height: 5px;
      background:#ffff;
    }

  .react-grid-item::-webkit-scrollbar-thumb{
    background: rgb(171, 173, 173);
    border-radius: 5px;
  }

  /* .react-grid-item.cssTransforms {
    transition-property: transform;
  } */
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }
  
  /* .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  } */
  
  .react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  
  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
    z-index: 9999;
  }
  
  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    /* border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4); */
  }
  
  /* body {
    background: white;
    overflow: scroll;
  } */
  #content {
    width: 100%;
  }
  
  .layoutJSON {
    background: white;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
  }
  .columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
  }
  
  .react-grid-item:not(.react-grid-placeholder) {
    background: white;
  }
  .react-grid-item.resizing {
    opacity: 0.9;
  }
  .react-grid-item.static {
    background: white;
  }
  .react-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 24px;
  }
  .react-grid-item .minMax {
    font-size: 12px;
  }
  .react-grid-item .add {
    cursor: pointer;
  }
  .react-grid-dragHandleExample {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  li b {
    font-size: 19px;
    line-height: 14px;
  }
  
  .toolbox {
    background-color: white;
    width: 100%;
    height: 120px;
    overflow: scroll;
  }
  
  .hide-button {
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 0px;
    right: 5px;
  }
  
  .toolbox__title {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .toolbox__items {
    display: block;
  }
  .toolbox__items__item {
    display: inline-block;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: 5px;
    border: 1px solid black;
    background-color: #ddd;
  }
  .react-grid-item:hover{
    z-index: 10;
    }