body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
  scrollbar-width: thin;
  padding-bottom: 40px;
  position: relative;
  min-height: 100vh;                                       /*firefox*/
  scrollbar-color: rgb(105, 103, 103) rgb(223, 213, 213);  /*firefox*/
}
body::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  background-color: rgb(223, 213, 213);
}
body::-webkit-scrollbar-thumb {
  background: rgb(105, 103, 103); 
  border-radius: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rc-menu-submenu-popup {
  position: absolute!important;
  z-index: 99!important;
 }

.radioForm {
  height:40px
}

.radioForm .form-group .custom-radio .custom-control-description{
  margin-top: -0.50rem!important;
}

.info-react-table{
   overflow-y: auto!important;
   overflow-x: auto!important;
   scrollbar-width: thin!important; 
   scrollbar-color:grey!important;
}

.info-react-table::-webkit-scrollbar{
  width: 5px!important;
  height: 5px!important;
  background:whitesmoke!important;
}
.info-react-table::-webkit-scrollbar-thumb{
   background: grey!important;
   border-radius: 5px;
}

.customeTheme {
 color:white !important;
 background-color: rgb(207, 45, 45) !important;
 font-size: 13px !important;
 height: 50px!important;
 font-weight: bold!important;
}


.customeTheme.type-error.place-bottom:after{
  border-bottom-color:rgb(207, 45, 45)!important;
  border-bottom-style:solid;
  border-bottom-width:6px
}
